<template>
  <div>
    <HeroHeader :titles="titles" />
    <v-container class="my-10">
      <!-- Leadership Team -->

      <!-- Integrate the Teams component -->
      <Teams />

      <!-- Additional Team Information -->
      <v-row class="mt-10">
        <v-col cols="12">
          <v-card class="pa-6">
            <v-row>
              <v-col cols="12" md="6">
                <h3 class="text-h5 font-weight-bold mb-4">Join Our Team</h3>
                <p class="text-body-1">
                  We're always looking for talented individuals to join our
                  team. If you're passionate about real estate and customer
                  service, we'd love to hear from you.
                </p>
                <v-btn color="primary" class="mt-4" to="/contact-us">
                  Contact Us
                </v-btn>
              </v-col>
              <v-col cols="12" md="6">
                <h3 class="text-h5 font-weight-bold mb-4">Our Values</h3>
                <v-list>
                  <v-list-item>
                    <v-list-item-icon>
                      <v-icon color="primary">mdi-check-circle</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>Integrity</v-list-item-title>
                      <v-list-item-subtitle
                        >We conduct our business with honesty and
                        transparency</v-list-item-subtitle
                      >
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-icon>
                      <v-icon color="primary">mdi-check-circle</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>Excellence</v-list-item-title>
                      <v-list-item-subtitle
                        >We strive for excellence in everything we
                        do</v-list-item-subtitle
                      >
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-icon>
                      <v-icon color="primary">mdi-check-circle</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>Customer Focus</v-list-item-title>
                      <v-list-item-subtitle
                        >Our clients' needs are at the center of our
                        business</v-list-item-subtitle
                      >
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import HeroHeader from "@/components/HeroHeader";
import Teams from "@/components/Teams";

export default {
  name: "OurTeamView",
  components: {
    HeroHeader,
    Teams,
  },
  data() {
    return {
      titles: {
        mainTitle: "Our Team",
        subTitle: "Meet the people behind our success",
      },
    };
  },
  mounted() {
    // Ensure team data is loaded
    this.$store.dispatch("fetchTeam");
  },
  metaInfo() {
    return {
      title: "Our Team",
      titleTemplate: this.$root.$options.titleTemplate,
    };
  },
};
</script>

<style scoped>
.h-100 {
  height: 100%;
}
.team-card {
  transition: transform 0.3s;
}
.team-card:hover {
  transform: translateY(-5px);
}
.team-image {
  object-fit: cover;
}
</style>
