<template>
  <div>
    <HeroHeader :titles="titles" />
    <v-container class="my-10">
      <v-row>
        <v-col cols="12" class="text-center mb-8">
          <h2 class="text-h4 font-weight-bold primary--text">
            Sold Properties
          </h2>
          <p class="text-subtitle-1 mt-4">
            Browse through our successfully sold properties and see our track
            record.
          </p>
        </v-col>
      </v-row>

      <!-- <v-row>
        <v-col cols="12">
          <v-card class="mb-6 pa-4">
            <v-row>
              <v-col cols="12" md="3">
                <v-select
                  v-model="filters.propertyType"
                  :items="propertyTypes"
                  label="Property Type"
                  outlined
                  dense
                ></v-select>
              </v-col>
              <v-col cols="12" md="3">
                <v-select
                  v-model="filters.location"
                  :items="locations"
                  label="Location"
                  outlined
                  dense
                ></v-select>
              </v-col>
              <v-col cols="12" md="3">
                <v-select
                  v-model="filters.priceRange"
                  :items="priceRanges"
                  label="Price Range"
                  outlined
                  dense
                ></v-select>
              </v-col>
              <v-col cols="12" md="3">
                <v-btn
                  color="primary"
                  block
                  class="mt-2"
                  @click="fetchSoldProperties"
                >
                  <v-icon left>mdi-filter</v-icon>
                  Apply Filters
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row> -->

      <v-row v-if="isLoading">
        <v-col cols="12" class="text-center">
          <v-progress-circular
            indeterminate
            color="primary"
            size="64"
          ></v-progress-circular>
        </v-col>
      </v-row>

      <v-row v-else-if="soldProperties.length === 0">
        <v-col cols="12" class="text-center">
          <v-alert type="info">
            No sold properties found matching your criteria.
          </v-alert>
        </v-col>
      </v-row>

      <v-row v-else>
        <v-col
          v-for="(property, index) in soldProperties"
          :key="index"
          cols="12"
          md="4"
          class="mb-6"
        >
          <PropertyCard :item="property" :sold="true" />
        </v-col>
      </v-row>

      <v-row v-if="pagination.totalPages > 1">
        <v-col cols="12" class="text-center">
          <v-pagination
            v-model="pagination.page"
            :length="pagination.totalPages"
            @input="handlePageChange"
          ></v-pagination>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import HeroHeader from "@/components/HeroHeader";
import PropertyCard from "@/components/PropertyCard";
import { mapState } from "vuex";

export default {
  name: "SoldPropertyView",
  components: {
    HeroHeader,
    PropertyCard,
  },
  data() {
    return {
      titles: {
        mainTitle: "Sold Properties",
        subTitle: "Our Success Stories",
      },
      isLoading: false,
      filters: {
        propertyType: "All",
        location: "All",
        priceRange: "All",
      },
      propertyTypes: ["All", "House", "Apartment", "Land", "Commercial"],
      locations: [
        "All",
        "Kathmandu",
        "Pokhara",
        "Chitwan",
        "Bhaktapur",
        "Lalitpur",
      ],
      priceRanges: [
        "All",
        "Less than 50 Lakhs",
        "50 Lakhs - 1 Crore",
        "1 Crore - 2 Crore",
        "2 Crore - 5 Crore",
        "Above 5 Crore",
      ],
      pagination: {
        page: 1,
        totalPages: 0,
        itemsPerPage: 9,
      },
    };
  },
  computed: {
    ...mapState(["soldProperties"]),
  },
  methods: {
    fetchSoldProperties() {
      this.isLoading = true;

      // Create filter parameters
      const params = {
        page: this.pagination.page,
        propertyType:
          this.filters.propertyType !== "All"
            ? this.filters.propertyType
            : null,
        location:
          this.filters.location !== "All" ? this.filters.location : null,
        priceRange:
          this.filters.priceRange !== "All" ? this.filters.priceRange : null,
      };

      // Dispatch action to fetch sold properties
      this.$store
        .dispatch("fetchSoldProperties", params)
        .then((response) => {
          if (response && response.meta) {
            this.pagination.totalPages = response.meta.last_page;
          }
          this.isLoading = false;
        })
        .catch((error) => {
          console.error("Error fetching sold properties:", error);
          this.isLoading = false;
        });
    },
    handlePageChange(page) {
      this.pagination.page = page;
      this.fetchSoldProperties();
      // Scroll to top of the property list
      window.scrollTo({
        top: document.querySelector(".my-10").offsetTop,
        behavior: "smooth",
      });
    },
  },
  mounted() {
    this.fetchSoldProperties();
  },
  metaInfo() {
    return {
      title: "Sold Properties",
      titleTemplate: this.$root.$options.titleTemplate,
    };
  },
};
</script>

<style scoped>
.h-100 {
  height: 100%;
}
.property-card {
  transition: transform 0.3s;
}
.property-card:hover {
  transform: translateY(-5px);
}
.sold-badge {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: rgba(244, 67, 54, 0.9);
  color: white;
  padding: 5px 10px;
  border-radius: 4px;
  font-weight: bold;
}
</style>
