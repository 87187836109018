<template>
  <div>
    <HeroHeader :titles="titles" />
    <v-container class="my-10">
      <!-- Introduction Section -->
      <v-row>
        <v-col cols="12" class="text-center mb-8">
          <h2 class="text-h4 font-weight-bold primary--text">Our Services</h2>
          <p class="text-subtitle-1 mt-4">
            We offer a comprehensive range of real estate services to meet all
            your property needs
          </p>
        </v-col>
      </v-row>

      <!-- Services Grid -->
      <v-row>
        <v-col
          v-for="(service, index) in services"
          :key="index"
          cols="12"
          md="4"
          class="mb-6"
        >
          <v-card
            class="service-card h-100"
            elevation="3"
            :class="`service-card-${index}`"
          >
            <v-card-title class="d-flex align-center">
              <v-avatar color="primary" size="56" class="mr-4">
                <v-icon dark size="32">{{ service.icon }}</v-icon>
              </v-avatar>
              <span class="text-h6">{{ service.title }}</span>
            </v-card-title>
            <v-card-text>
              <p class="text-body-1">{{ service.description }}</p>
              <v-divider class="my-3"></v-divider>
              <v-list dense>
                <v-list-item
                  v-for="(feature, i) in service.features"
                  :key="i"
                  class="px-0"
                >
                  <v-list-item-icon class="mr-2">
                    <v-icon color="success" small>mdi-check-circle</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title class="text-body-2">{{
                      feature
                    }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <!-- Call to Action -->
      <v-row class="mt-8">
        <v-col cols="12" class="text-center">
          <v-card class="pa-6 primary">
            <v-card-text class="white--text text-h6">
              Ready to get started with our services?
            </v-card-text>
            <v-card-actions class="justify-center">
              <v-btn color="white" class="black--text" large to="/contact-us">
                Contact Us Today
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>

      <!-- Testimonials Section -->
      <v-row class="mt-12">
        <v-col cols="12" class="text-center mb-6">
          <h3 class="text-h5 font-weight-bold primary--text">
            What Our Clients Say
          </h3>
        </v-col>
        <v-col cols="12">
          <v-carousel hide-delimiters show-arrows-on-hover height="300">
            <v-carousel-item v-for="(testimonial, i) in testimonials" :key="i">
              <v-sheet height="100%" class="d-flex align-center justify-center">
                <v-card
                  max-width="800"
                  class="mx-auto pa-6 testimonial-card"
                  flat
                >
                  <v-card-text class="text-center">
                    <v-icon color="amber" large>mdi-format-quote-open</v-icon>
                    <p class="text-h6 font-italic my-4">
                      {{ testimonial.quote }}
                    </p>
                    <v-icon color="amber" large>mdi-format-quote-close</v-icon>
                    <div class="mt-4">
                      <p class="text-subtitle-1 font-weight-bold mb-1">
                        {{ testimonial.name }}
                      </p>
                      <p class="text-caption">{{ testimonial.position }}</p>
                    </div>
                  </v-card-text>
                </v-card>
              </v-sheet>
            </v-carousel-item>
          </v-carousel>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import HeroHeader from "@/components/HeroHeader";
import { mapState } from "vuex";

export default {
  name: "OurServicesView",
  components: {
    HeroHeader,
  },
  data() {
    return {
      titles: {
        mainTitle: "Our Services",
        subTitle: "Comprehensive Real Estate Solutions",
      },
      services: [
        {
          title: "Property Listing & Marketing",
          description:
            "We create compelling property listings and implement strategic marketing campaigns to maximize visibility and attract qualified buyers.",
          icon: "mdi-home-search",
          features: [
            "Professional photography",
            "Virtual tours",
            "Social media promotion",
            "Featured listings on top platforms",
          ],
        },
        {
          title: "Market Analysis",
          description:
            "Our experts analyze current market trends and conditions to provide you with valuable insights for making informed real estate decisions.",
          icon: "mdi-chart-line",
          features: [
            "Comprehensive market reports",
            "Price trend analysis",
            "Neighborhood comparisons",
            "Investment opportunity assessment",
          ],
        },
        {
          title: "Property Valuation",
          description:
            "We provide accurate property valuations based on thorough assessments of your property's features, condition, location, and market factors.",
          icon: "mdi-calculator-variant",
          features: [
            "Detailed property assessment",
            "Comparative market analysis",
            "Value-adding improvement suggestions",
            "Regular valuation updates",
          ],
        },
        {
          title: "Paperwork Assistance",
          description:
            "We handle all the complex documentation and paperwork involved in real estate transactions to ensure a smooth and legally compliant process.",
          icon: "mdi-file-document-multiple",
          features: [
            "Contract preparation",
            "Legal document review",
            "Compliance verification",
            "Secure record keeping",
          ],
        },
        {
          title: "Property Tours",
          description:
            "We organize and conduct personalized property tours for potential buyers, highlighting key features and answering all questions.",
          icon: "mdi-eye",
          features: [
            "Scheduled in-person viewings",
            "Virtual tour options",
            "Neighborhood orientation",
            "Detailed property information",
          ],
        },
        {
          title: "Investment Advice",
          description:
            "Our experienced advisors provide strategic investment guidance to help you maximize returns and build a successful real estate portfolio.",
          icon: "mdi-chart-areaspline",
          features: [
            "ROI analysis",
            "Investment strategy development",
            "Risk assessment",
            "Portfolio diversification guidance",
          ],
        },
        {
          title: "Paid Promotion",
          description:
            "We offer premium promotional services to give your property listing maximum exposure across multiple high-traffic platforms.",
          icon: "mdi-bullhorn",
          features: [
            "Targeted digital advertising",
            "Premium listing placement",
            "Email marketing campaigns",
            "Print media exposure",
          ],
        },
        {
          title: "Property Management",
          description:
            "Our comprehensive property management services take care of all aspects of rental property ownership, from tenant screening to maintenance.",
          icon: "mdi-home-city",
          features: [
            "Tenant screening and selection",
            "Rent collection",
            "Maintenance coordination",
            "Regular property inspections",
          ],
        },
        {
          title: "Legal & Financial Services",
          description:
            "We provide access to expert legal and financial services to ensure your real estate transactions are secure, compliant, and financially sound.",
          icon: "mdi-scale-balance",
          features: [
            "Legal consultation",
            "Mortgage assistance",
            "Tax planning advice",
            "Escrow services",
          ],
        },
      ],
      testimonials: [
        {
          quote:
            "The team at Property Nepal provided exceptional service throughout our home buying process. Their market analysis was spot-on and helped us make a confident decision.",
          name: "Rajesh Sharma",
          position: "Homeowner in Kathmandu",
        },
        {
          quote:
            "I was impressed by the professional property valuation and marketing strategy. My property sold within two weeks at a price above my expectations!",
          name: "Sunita Thapa",
          position: "Property Seller",
        },
        {
          quote:
            "Their investment advice has been invaluable for building my real estate portfolio. I've seen consistent returns thanks to their market insights.",
          name: "Binod Adhikari",
          position: "Real Estate Investor",
        },
      ],
    };
  },
  computed: {
    ...mapState(["testimonials"]),
  },
  metaInfo() {
    return {
      title: "Our Services",
      titleTemplate: this.$root.$options.titleTemplate,
    };
  },
};
</script>

<style scoped>
.h-100 {
  height: 100%;
}
.service-card {
  transition: transform 0.3s, box-shadow 0.3s;
  border-top: 4px solid var(--v-primary-base);
}
.service-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 12px 20px rgba(0, 0, 0, 0.1) !important;
}
.service-card-0 {
  border-top-color: #1e88e5;
}
.service-card-1 {
  border-top-color: #43a047;
}
.service-card-2 {
  border-top-color: #fb8c00;
}
.service-card-3 {
  border-top-color: #e53935;
}
.service-card-4 {
  border-top-color: #8e24aa;
}
.service-card-5 {
  border-top-color: #3949ab;
}
.service-card-6 {
  border-top-color: #00897b;
}
.service-card-7 {
  border-top-color: #fdd835;
}
.service-card-8 {
  border-top-color: #6d4c41;
}

.testimonial-card {
  background-color: #f5f7fa;
  border-radius: 8px;
}
</style>
