<template>
  <v-container fluid class="p-content p-premium-properties fix-width pt-16">
    <v-row>
      <v-col class="col-12 text-center">
        <v-btn class="darkblue--btn">Latest Blogs</v-btn>
      </v-col>
    </v-row>

    <v-row justify="center" class="mt-6">
      <v-col
        cols="12"
        md="4"
        v-for="(blog, index) in homeBlogs"
        :key="index"
        class="pa-4"
      >
        <BlogCard :post="blog" />
      </v-col>
    </v-row>

    <v-row class="mt-6">
      <v-col cols="12" class="text-center">
        <v-btn color="primary" outlined to="/blogs" class="px-6">
          View All Blogs
          <v-icon right>mdi-arrow-right</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import BlogCard from "@/components/BlogCard";

export default {
  name: "HomeBlogSection",
  components: {
    BlogCard,
  },
  computed: {
    ...mapState(["homeBlogs"]),
  },
  methods: {
    formatDate(dateString) {
      const options = { year: "numeric", month: "long", day: "numeric" };
      return new Date(dateString).toLocaleDateString(undefined, options);
    },
    truncateText(text, length) {
      if (!text) return "";
      return text.length > length ? text.substring(0, length) + "..." : text;
    },
    navigateToBlog(slug) {
      this.$router.push({ name: "singleBlog", params: { slug } });
    },
  },
};
</script>

<style scoped>
/* No need for card styling as it's now handled by the BlogCard component */
</style>
