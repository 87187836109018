<template>
  <div>
    <HeroHeader :titles="titles" />
    <v-container class="my-10">
      <v-row>
        <v-col cols="12" class="text-center mb-8">
          <h2 class="text-h4 font-weight-bold primary--text">Our Branches</h2>
          <p class="text-subtitle-1 mt-4">
            Find our branches across different locations to serve you better.
          </p>
        </v-col>
      </v-row>

      <v-row>
        <v-col
          v-for="(branch, index) in branches"
          :key="index"
          cols="12"
          md="4"
          class="mb-6"
        >
          <v-card elevation="3" class="h-100">
            <v-img
              :src="branch.image"
              height="200"
              class="white--text align-end"
              gradient="to bottom, rgba(0,0,0,0) 60%, rgba(0,0,0,0.8)"
            >
              <v-card-title>{{ branch.location }}</v-card-title>
            </v-img>
            <v-card-text>
              <v-row no-gutters align="center" class="mb-2">
                <v-icon color="primary" class="mr-2">mdi-map-marker</v-icon>
                <span>{{ branch.address }}</span>
              </v-row>
              <v-row no-gutters align="center" class="mb-2">
                <v-icon color="primary" class="mr-2">mdi-phone</v-icon>
                <span>{{ branch.phone }}</span>
              </v-row>
              <v-row no-gutters align="center" class="mb-2">
                <v-icon color="primary" class="mr-2">mdi-email</v-icon>
                <span>{{ branch.email }}</span>
              </v-row>
              <p class="mt-3">{{ branch.description }}</p>
            </v-card-text>
            <v-card-actions>
              <v-btn
                text
                color="primary"
                :href="branch.mapLink"
                target="_blank"
              >
                <v-icon left>mdi-map</v-icon>
                View on Map
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import HeroHeader from "@/components/HeroHeader";

export default {
  name: "OurBranchesView",
  components: {
    HeroHeader,
  },
  data() {
    return {
      titles: {
        mainTitle: "Our Branches",
        subTitle: "Find us across different locations",
      },
      branches: [
        {
          location: "Kathmandu",
          address: "Thamel, Kathmandu, Nepal",
          phone: "+977-1-4123456",
          email: "kathmandu@propertyinnepal.com.np",
          description:
            "Our headquarters located in the heart of Kathmandu, offering comprehensive real estate services.",
          image:
            "https://images.unsplash.com/photo-1605640840605-14ac1855827b?ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80",
          mapLink: "https://maps.google.com/?q=Thamel,Kathmandu,Nepal",
        },
        {
          location: "Pokhara",
          address: "Lakeside, Pokhara, Nepal",
          phone: "+977-61-467890",
          email: "pokhara@propertyinnepal.com.np",
          description:
            "Serving the beautiful lake city with premium property listings and expert advice.",
          image:
            "https://images.unsplash.com/photo-1605640840605-14ac1855827b?ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80",
          mapLink: "https://maps.google.com/?q=Lakeside,Pokhara,Nepal",
        },
        {
          location: "Chitwan",
          address: "Bharatpur, Chitwan, Nepal",
          phone: "+977-56-789012",
          email: "chitwan@propertyinnepal.com.np",
          description:
            "Specializing in residential and commercial properties in the Chitwan region.",
          image:
            "https://images.unsplash.com/photo-1605640840605-14ac1855827b?ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80",
          mapLink: "https://maps.google.com/?q=Bharatpur,Chitwan,Nepal",
        },
      ],
    };
  },
  metaInfo() {
    return {
      title: "Our Branches",
      titleTemplate: this.$root.$options.titleTemplate,
    };
  },
};
</script>

<style scoped>
.h-100 {
  height: 100%;
}
</style>
