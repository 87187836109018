<template>
  <div>
    <!-- <HeroHeader :titles="titles" /> -->

    <div class="mx-auto mt-1">
      <!--About Us-->
      <v-container fluid class="pt-md-0">
        <v-row
          justify="center"
          v-for="content in contents"
          :key="content.id"
          v-bind:class="[{ reverse: content.alignRight }, 'my-1']"
        >
          <v-col class="content-text-container" cols="12" md="5">
            <v-row>
              <p class="text-center text-h5 col-12">About Us</p>

              <div v-html="about_data.description"></div>
            </v-row>
          </v-col>
          <v-col cols="12" md="5">
            <v-img
              :src="require('./../assets/img/about-us.png')"
              class="content-image-container"
            ></v-img>
          </v-col>
        </v-row>
      </v-container>
      <!--End Of About Us-->

      <v-container fluid>
        <v-row justify="center">
          <v-col cols="12" md="4">
            <!-- Mission-->
            <v-card
              class="mx-auto my-4 m-v-hover"
              max-width="25em"
              style="height: 20em"
            >
              <div class="content-container">
                <div class="icon-container">
                  <v-icon color="#00b8e6" size="68">mdi-bullseye-arrow</v-icon>
                </div>
                <v-card-title class="m-v-title"> Our Mission</v-card-title>
                <v-card-text
                  class="m-v-text"
                  v-text="about_data.mission"
                ></v-card-text>
              </div>
            </v-card>
            <!-- End of Mission-->
          </v-col>
          <v-col cols="12" md="4">
            <!--Vision-->
            <v-card
              class="mx-auto my-4 m-v-hover"
              max-width="25em"
              style="height: 20em"
            >
              <div class="content-container">
                <div class="icon-container">
                  <v-icon color="#00b8e6" size="68">mdi-eye</v-icon>
                </div>
                <v-card-title class="m-v-title">Our Vision</v-card-title>
                <v-card-text
                  class="m-v-text"
                  v-text="about_data.vision"
                ></v-card-text>
              </div>
            </v-card>
            <!--End of Vision-->
          </v-col>
        </v-row>
      </v-container>

      <div
        ref="message-from-ceo"
        style="
          position: relative;
          top: 400px;
          left: 0;
          height: 1px;
          background: red;
          width: 1px;
        "
      />
      <MessageFromCEO />

      <!-- How It Works Section-->
      <v-container fluid class="how-it-works-section py-10">
        <v-row justify="center">
          <v-col cols="12" class="text-center mb-8">
            <h2 class="text-h4 font-weight-bold primary--text">How It Works</h2>
            <p class="text-subtitle-1 mt-4">
              Our streamlined process makes buying and selling property simple
              and efficient
            </p>
          </v-col>
        </v-row>

        <v-row justify="center">
          <v-col cols="12" md="10">
            <v-timeline align-top dense>
              <v-timeline-item
                v-for="(step, index) in processSteps"
                :key="index"
                :color="step.color"
                :icon="step.icon"
                fill-dot
              >
                <v-card class="elevation-3 timeline-card">
                  <v-card-title class="headline">
                    <span class="step-number">{{ index + 1 }}</span>
                    {{ step.title }}
                  </v-card-title>
                  <v-card-text>
                    <p>{{ step.description }}</p>
                    <v-chip-group v-if="step.features && step.features.length">
                      <v-chip
                        v-for="(feature, i) in step.features"
                        :key="i"
                        small
                        outlined
                        class="ma-1"
                      >
                        {{ feature }}
                      </v-chip>
                    </v-chip-group>
                  </v-card-text>
                </v-card>
              </v-timeline-item>
            </v-timeline>
          </v-col>
        </v-row>

        <v-row justify="center" class="mt-8">
          <v-col cols="12" md="6" class="text-center">
            <v-btn
              color="primary"
              x-large
              class="px-8"
              elevation="2"
              to="/contact-us"
            >
              <v-icon left>mdi-home-search</v-icon>
              Start Your Property Journey
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>
<script>
// import HeroHeader from "@/components/HeroHeader";
import { mapState } from "vuex";
import MessageFromCEO from "@/components/MessageFromCEO";

export default {
  name: "AboutUs",
  components: { MessageFromCEO },
  metaInfo() {
    return {
      title: "About Us",
      titleTemplate: this.$root.$options.titleTemplate,
    };
  },
  methods: {},
  computed: {
    ...mapState(["about_data"]),
  },
  data() {
    return {
      titles: {
        mainTitle: "About Us",
        subTitle: "Find Your Property With Us",
      },
      vision:
        "“To adapt and evolve to continue to offer beyond expectation service and results, through creative thinking, leadership, initiative, respect and passion. ”",
      mission:
        "“To enthusiastically serve our clients by providing tenants with homes and workplaces that they can be proud of.”",
      contents: [
        {
          id: 1,
          text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia, molestiae quas vel sint commodi repudiandae consequuntur voluptatum laborum numquam blanditiis harum quisquam eius sed odit fugiat iusto fuga praesentium optio, eaque rerum! Provident similique accusantium nemo autem. Veritatis obcaecati tenetur iure eius earum ut molestias architecto voluptate aliquam nihil, eveniet aliquid culpa officia aut! Impedit sit sunt quaerat, odit, tenetur error, harum nesciunt ipsum debitis quas aliquid",
          img: "https://img.freepik.com/free-vector/marketing-consulting-concept-illustration_114360-9659.jpg?w=740&t=st=1689234829~exp=1689235429~hmac=ba8991f44427884c90d24c76e02f01c72ee80a4908e0676278ba2483af87ca2e",
          alignRight: false,
        },
      ],
      tag: {
        mainTitle: "THE BEST REAL STATE OUT THERE",
        subTitle:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia, molestiae quas vel sint commodi repudiandae consequuntur voluptatum laborum numquam blanditiis harum quisquam eius sed odit fugiat iusto fuga praesentium optio, eaque rerum! Provident similique accusantium nemo autem. Veritatis obcaecati tenetur iure eius earum ut molestias architecto voluptate aliquam nihil, eveniet aliquid culpa officia aut! Impedit sit sunt quaerat, odit, tenetur error, harum nesciunt ipsum debitis quas aliquid",
      },
      photos: [
        {
          id: 1,
          url: "https://www.realestateinnepal.com/wp-content/uploads/2019/03/2.jpeg",
        },
        {
          id: 2,
          url: "https://www.neopropertynepal.com/uploads/articles/images/house-building.jpg",
        },
        {
          id: 3,
          url: "https://epropertynepal.com/system/photos/21602/original_1.jpg?1660639794",
        },
        {
          id: 4,
          url: "https://www.propertynepal.com/images/properties/892/1682586802.jpeg",
        },
      ],

      cards: [
        {
          title: "Evaluate Property",
          icon: "mdi-book-open-page-variant",
        },
        {
          title: "Meet Our Agent",
          icon: "mdi-account-tie",
        },
        {
          title: "Close The Deal",
          icon: "mdi-handshake",
        },
      ],
      processSteps: [
        {
          title: "Listing Property",
          description:
            "Property owners list their properties with us, providing detailed information and documentation. We verify all details to ensure accuracy and create compelling listings that highlight the property's best features.",
          icon: "mdi-home-plus",
          color: "#1E88E5",
          features: [
            "Free Consultation",
            "Property Verification",
            "Documentation Support",
          ],
        },
        {
          title: "Property Evaluation",
          description:
            "Our experts conduct a thorough evaluation of the property to determine its fair market value based on location, condition, and current market trends. This helps set the right price to attract potential buyers.",
          icon: "mdi-calculator-variant",
          color: "#43A047",
          features: [
            "Market Analysis",
            "Condition Assessment",
            "Price Recommendation",
          ],
        },
        {
          title: "Meet Agent and Friends",
          description:
            "We assign dedicated agents who understand your requirements and guide you through the entire process. Our network of professionals including legal advisors and financial consultants are available to assist you.",
          icon: "mdi-account-group",
          color: "#FB8C00",
          features: [
            "Experienced Professionals",
            "Personalized Service",
            "Expert Network",
          ],
        },
        {
          title: "Marketing",
          description:
            "We implement comprehensive marketing strategies to showcase your property to potential buyers through multiple channels including online listings, social media, and our extensive network of clients looking for properties.",
          icon: "mdi-bullhorn",
          color: "#E53935",
          features: [
            "Professional Photography",
            "Online Listings",
            "Social Media Promotion",
          ],
        },
        {
          title: "Client Visits and Convincing",
          description:
            "We arrange and coordinate property viewings for interested buyers, highlighting the key features and addressing any questions or concerns. Our agents are skilled at presenting properties in the best light to convince potential buyers.",
          icon: "mdi-eye",
          color: "#8E24AA",
          features: [
            "Scheduled Viewings",
            "Virtual Tours",
            "Feedback Collection",
          ],
        },
        {
          title: "Buyer-Seller Meeting",
          description:
            "We facilitate meetings between buyers and sellers to discuss terms, negotiate prices, and address any concerns from either party. Our agents act as mediators to ensure smooth communication and fair negotiations.",
          icon: "mdi-handshake-outline",
          color: "#3949AB",
          features: [
            "Negotiation Support",
            "Transparent Communication",
            "Professional Mediation",
          ],
        },
        {
          title: "Close The Deal",
          description:
            "We assist with all paperwork, legal requirements, and financial transactions to ensure a smooth and successful closing process. Our team handles the details so you can focus on your next steps.",
          icon: "mdi-handshake",
          color: "#00897B",
          features: [
            "Documentation Assistance",
            "Legal Compliance",
            "Smooth Transition",
          ],
        },
      ],
    };
  },
  watch: {
    "$route.hash": function () {
      const hash = this.$route.hash.substring(1);

      window.setTimeout(() => {
        this.$nextTick(() => {
          if (this.$refs[hash]) {
            this.$refs[hash].scrollIntoView({
              behavior: "smooth",
              block: "center", // scroll to the bottom of the element
              inline: "center", // keep the element in the viewport
            });
          }
        });
      }, 1000);
    },
  },
  created() {
    if (this.$route.hash) {
      const hash = this.$route.hash.substring(1);

      window.setTimeout(() => {
        this.$nextTick(() => {
          if (this.$refs[hash]) {
            this.$refs[hash].scrollIntoView({
              behavior: "smooth",
              block: "center", // scroll to the bottom of the element
              inline: "center", // keep the element in the viewport
            });
          }
        });
      }, 1000);
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Barlow&family=Diphylleia&display=swap");

.page {
  margin-top: 0px;
}

.m-v-title {
  font-family: "Diphylleia", serif;
  font-size: 30px;
  font-weight: 700;
}

.m-v-text {
  font-family: "Barlow", sans-serif;
  font-size: 16px;
  font-weight: 300;
}

.icon-container {
  display: inline-block;
  padding: 10px;
  position: relative;
}

.icon-background {
  font-size: 24px;
  background-color: #00b8e6;
  padding: 35px;
  position: relative;
  z-index: 1;
  clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
}

.content-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  justify-content: center;
}

.m-v-hover {
  transition: all 300ms ease-in-out 0s;
}

.m-v-hover:hover {
  transform: scale(1.08);
  transition: transform 0.3s ease-in-out;
  box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
}

.content-text-container {
  display: flex;
  font-size: 20px;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.content-image-container {
  width: 90%;
}

.reverse {
  flex-direction: row-reverse;
}

.card-hover:hover {
  transform: scale(1.08);
  transition: transform 0.2s ease-in-out;
}

.expanded-panel {
  background-color: #184a62;
  color: white;
}

.collapsed-panel {
  background-color: #e0f3f6;
}

@media screen and (max-width: 480px) {
  .collapsed-panel {
    font-size: 12px;
  }
}

@media screen and (min-width: 481px) and (max-width: 768px) {
  .collapsed-panel .about-tag {
    font-size: 14px;
  }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
  .collapsed-panel {
    font-size: 16px;
  }
}

@media screen and (min-width: 1025px) {
  .collapsed-panel {
    font-size: 18px;
  }
}

.how-it-works-section {
  background-color: #f5f7fa;
  position: relative;
  padding-bottom: 60px;
}

.how-it-works-section::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("data:image/svg+xml,%3Csvg width='100' height='100' viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11 18c3.866 0 7-3.134 7-7s-3.134-7-7-7-7 3.134-7 7 3.134 7 7 7zm48 25c3.866 0 7-3.134 7-7s-3.134-7-7-7-7 3.134-7 7 3.134 7 7 7zm-43-7c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zm63 31c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zM34 90c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zm56-76c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zM12 86c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm28-65c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm23-11c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm-6 60c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm29 22c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zM32 63c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm57-13c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm-9-21c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM60 91c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM35 41c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM12 60c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2z' fill='%2300b8e6' fill-opacity='0.05' fill-rule='evenodd'/%3E%3C/svg%3E");
  opacity: 0.5;
  z-index: 0;
}

.timeline-card {
  border-left: 4px solid;
  transition: transform 0.3s;
}

.timeline-card:hover {
  transform: translateY(-5px);
}

.step-number {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: var(--v-primary-base);
  color: white;
  margin-right: 12px;
  font-size: 18px;
  font-weight: bold;
}

.v-timeline-item:nth-child(1) .timeline-card {
  border-left-color: #1e88e5;
}

.v-timeline-item:nth-child(2) .timeline-card {
  border-left-color: #43a047;
}

.v-timeline-item:nth-child(3) .timeline-card {
  border-left-color: #fb8c00;
}

.v-timeline-item:nth-child(4) .timeline-card {
  border-left-color: #e53935;
}

.v-timeline-item:nth-child(5) .timeline-card {
  border-left-color: #8e24aa;
}

.v-timeline-item:nth-child(6) .timeline-card {
  border-left-color: #3949ab;
}

.v-timeline-item:nth-child(7) .timeline-card {
  border-left-color: #00897b;
}
</style>
