<template>
  <div v-if="teams.length > 0">
    <v-row justify="center">
      <v-row>
        <v-col cols="12" class="text-center mb-8">
          <h2 class="text-h4 font-weight-bold primary--text">Our Team</h2>
          <p class="text-subtitle-1 mt-4">
            Meet our dedicated team of real estate professionals who are
            committed to providing you with exceptional service.
          </p>
        </v-col>
      </v-row>
      <v-col class="col-md-10 col-12">
        <v-row>
          <v-col
            class="col-lg-3 col-sm-4 col-12 mb-5"
            v-for="(team, n) in teams"
            :key="n"
          >
            <div class="pa-md-5 pa-sm-5">
              <v-row justify="center" align-content="center">
                <div
                  class="team-image-container"
                  :style="$inlineBgImage(team.image)"
                  @click="viewTeamProperties(team.id)"
                ></div>
                <v-col class="col-12 pb-0 mb-0">
                  <p class="team-person-name">{{ team.name }}</p>
                  <p class="team-person-post">
                    {{ team.position }}
                  </p>
                  <p class="team-person-post" style="margin-bottom: 10px">
                    ID: {{ team.pinid }}
                  </p>
                </v-col>
                <v-col class="col-12 text-center pt-0">
                  <a :href="team.fb" v-if="team.fb">
                    <v-icon class="team-fb-icon">mdi-facebook</v-icon>
                  </a>
                  <a :href="team.linkedin" v-if="team.linkedin">
                    <v-icon class="team-li-icon">mdi-linkedin</v-icon>
                  </a>
                  <a :href="team.insta" v-if="team.insta">
                    <v-icon class="team-ig-icon">mdi-instagram</v-icon>
                  </a>
                  <a :href="team.youtube" v-if="team.youtube">
                    <v-icon class="team-youtube-icon">mdi-youtube</v-icon>
                  </a>
                  <v-btn class="icon-btn"
                    ><a :href="team.tiktok" v-if="team.tiktok">
                      <v-img
                        class="team-tiktok-icon"
                        :src="require('@/assets/img/tiktok.png')"
                        max-width="18px"
                      /> </a
                  ></v-btn>
                  <v-btn
                    color="primary"
                    small
                    class="mt-2 d-block mx-auto"
                    @click="viewTeamProperties(team.id)"
                  >
                    List Properties
                  </v-btn>
                </v-col>
              </v-row>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "Teams",
  computed: {
    ...mapState(["teams"]),
  },
  methods: {
    viewTeamProperties(teamId) {
      this.$router.push(`/team/${teamId}/properties`);
    },
  },
};
</script>

<style scoped>
.icon-btn {
  padding: 0px;
  height: 20px !important;
  max-width: 20px !important;
  min-width: 20px !important;
  background-color: transparent !important;
  box-shadow: none !important;
}
.team-title {
  padding-top: 50px !important;
  padding-bottom: 50px !important;
  font-size: 38px;
  font-weight: bold;
  font-family: "Roboto", sans-serif;
  color: rgb(30, 77, 98);
}

.team-image-container {
  height: 220px;
  width: 220px;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  border: 2px solid rgba(98, 160, 194, 0.56);
  overflow: hidden;
  transition: transform 0.3s ease;
  cursor: pointer;
}

.team-image-container:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.team-title,
.team-person-name,
.team-person-post {
  text-align: center !important;
  padding-bottom: 0px;
  margin-bottom: 0px;
}
.team-person-name {
  font-size: 20px;
  font-weight: 600;
  text-transform: uppercase;
}
.team-person-post {
  font-size: 15px;
  color: rgba(0, 0, 0, 0.6);
  text-transform: uppercase;
  /* margin-bottom: 10px; */
}

.team-fb-icon,
.team-li-icon,
.team-youtube-icon,
.team-ig-icon,
.team-tiktok-icon {
  font-size: 1.8em;
  margin-left: 5px;
  display: inline-block;
}
</style>
