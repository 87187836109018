import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
import AboutView from "../views/AboutView.vue";
import ContactView from "../views/ContactView.vue";
import ListingView from "../views/TopListingView.vue";
import DynamicShop from "@/views/DynamicShop";
// import SingleProperty from "@/views/SingleProperty";
import UnitConverter from "@/views/UnitConverter";
import EMICalculator from "@/views/EMICalculator";
import PropertyRequest from "@/views/PropertyRequest";
import BlogView from "@/views/BlogView";
import BlogPostView from "@/views/BlogPostView";
import BlogCategoryView from "@/views/BlogCategoryView";
import BlogHashTagView from "@/views/BlogHashtagView";
import PropertyCompare from "@/views/PropertyCompare";
// import Login from "@/views/Login";
// import Register from "@/views/Register";
import AppLayout from "@/layouts/AppLayout";
import BlankLayout from "@/layouts/BlankLayout";
import BlogLayout from "@/layouts/BlogLayout";
import store from "@/store";
import RevampSingleProperty from "@/views/RevampSingleProperty";
import Error from "@/views/Error";
import OurBranchesView from "@/views/OurBranchesView";
import OurTeamView from "@/views/OurTeamView";
import OurServicesView from "@/views/OurServicesView";
import SoldPropertyView from "@/views/SoldPropertyView";
import VideosView from "@/views/VideosView";
import TeamMemberPropertiesView from "@/views/TeamMemberPropertiesView";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: AppLayout,
    children: [
      {
        path: "",
        name: "home",
        component: HomeView,
        beforeEnter: async (to, from, next) => {
          await store.commit("setLoading", true);
          await store.dispatch("fetchBanner").then((response) => {
            if (response) {
              response.forEach(function (banner) {
                const img = new Image();
                img.src = banner;
              });
            }
          });
          store.dispatch("fetchAchievements");
          store.dispatch("fetchTestimonials");
          store.dispatch("fetchHomeAbout");
          store.dispatch("fetchAboutData");
          store.dispatch("fetchFAQs");
          store.dispatch("fetchFrontEndFeatured");
          store.dispatch("fetchFrontEndPremium");
          store.dispatch("fetchFrontEndNewListing");
          store.dispatch("fetchUnderConstructionProperties");
          store.dispatch("fetchHomeBlogs");
          store.dispatch("fetchTopListing");

          await store.dispatch("fetchContactDetails").then((response) => {
            if (response) {
              const img = new Image();
              img.src = response;
            }
          });

          store.commit("setLoading", false);

          await next();
          Vue.prototype.$Progress.finish();
        },
      },
      {
        path: "/about-us",
        name: "about-us",
        component: AboutView,
        beforeEnter: async (to, from, next) => {
          await store.commit("setLoading", true);
          store.dispatch("fetchAboutData");
          store.dispatch("fetchFAQs");
          store.dispatch("fetchTeam");
          store.dispatch("fetchMessageFromCEO");

          await store.dispatch("fetchContactDetails").then((response) => {
            if (response) {
              const img = new Image();
              img.src = response;
            }
          });
          await store.commit("setLoading", false);
          Vue.prototype.$Progress.finish();
          await next();
        },
      },

      {
        path: "/contact-us",
        name: "contact-us",
        component: ContactView,
        beforeEnter: async (to, from, next) => {
          Vue.prototype.$Progress.finish();
          next();
        },
      },
      {
        path: "/top-listing",
        name: "top-listing",
        component: ListingView,
        beforeEnter: async (to, from, next) => {
          await store.commit("setLoading", true);
          await store.dispatch("fetchTopListing");
          await store.commit("setLoading", false);
          Vue.prototype.$Progress.finish();
          await next();
        },
      },
      {
        path: "/property/:slug",
        name: "singleProperty",
        component: RevampSingleProperty,
        beforeEnter: async (to, from, next) => {
          if (to.params.slug) {
            await store
              .dispatch("fetchProperty", to.params.slug)
              .then(async (response) => {
                if (
                  response.message &&
                  response.message === "Property Not Found"
                ) {
                  next({ name: "error" });
                }
                store.dispatch("fetchSimilarProperty", to.params.slug);
              });
            Vue.prototype.$Progress.finish();
            await next();
          }
        },
      },
      {
        path: "/unit-converter",
        name: "unitConverter",
        component: UnitConverter,
        beforeEnter: async (to, from, next) => {
          Vue.prototype.$Progress.finish();
          next();
        },
      },
      {
        path: "/emi",
        name: "emiCalculator",
        component: EMICalculator,
        beforeEnter: async (to, from, next) => {
          Vue.prototype.$Progress.finish();
          next();
        },
      },
      {
        path: "/property-request",
        name: "propertyRequest",
        component: PropertyRequest,
        beforeEnter: async (to, from, next) => {
          Vue.prototype.$Progress.finish();
          next();
        },
      },

      {
        path: "/compare",
        name: "compareProperty",
        component: PropertyCompare,
        beforeEnter: async (to, from, next) => {
          Vue.prototype.$Progress.finish();
          next();
        },
      },
      {
        path: "/our-branches",
        name: "our-branches",
        component: OurBranchesView,
        beforeEnter: async (to, from, next) => {
          Vue.prototype.$Progress.finish();
          next();
        },
      },
      {
        path: "/our-team",
        name: "our-team",
        component: OurTeamView,
        beforeEnter: async (to, from, next) => {
          Vue.prototype.$Progress.finish();
          next();
        },
      },
      {
        path: "/team/:teamId/properties",
        name: "team-member-properties",
        component: TeamMemberPropertiesView,
        beforeEnter: async (to, from, next) => {
          console.log(
            "Entering team member properties route with ID:",
            to.params.teamId
          );
          // Ensure team data is loaded
          await store.dispatch("fetchTeam");
          Vue.prototype.$Progress.finish();
          next();
        },
      },
      {
        path: "/our-services",
        name: "our-services",
        component: OurServicesView,
        beforeEnter: async (to, from, next) => {
          Vue.prototype.$Progress.finish();
          next();
        },
      },
      {
        path: "/sold-property",
        name: "sold-property",
        component: SoldPropertyView,
        beforeEnter: async (to, from, next) => {
          Vue.prototype.$Progress.finish();
          next();
        },
      },
      {
        path: "/videos",
        name: "videos",
        component: VideosView,
        beforeEnter: async (to, from, next) => {
          Vue.prototype.$Progress.finish();
          next();
        },
      },
      {
        path: "listings",
        name: "allListings",
        component: () => import("../views/AllListingsView.vue"),
        beforeEnter: async (to, from, next) => {
          Vue.prototype.$Progress.finish();
          next();
        },
      },
    ],
  },
  {
    path: "/",
    component: BlogLayout,
    beforeEnter: async (to, from, next) => {
      await store.dispatch("fetchHashtags");
      await store.dispatch("fetchCategories");
      await store.dispatch("fetchRecentBlogs");
      next();
    },
    children: [
      {
        path: "blogs",
        name: "blogs",
        component: BlogView,
        beforeEnter: async (to, from, next) => {
          await store.dispatch("fetchAllBlogs", 1);
          Vue.prototype.$Progress.finish();
          next();
        },
      },
      {
        path: "/blog/:slug",
        name: "blogPost",
        component: BlogPostView,
        beforeEnter: async (to, from, next) => {
          if (to.params.slug) {
            await store
              .dispatch("fetchBlog", to.params.slug)
              .then((response) => {
                if (response.message === "Blog Not Found") {
                  next(from);
                }
              });
            await next();
          } else {
            next(from);
          }
          Vue.prototype.$Progress.finish();
        },
      },
      {
        path: "blogs-category/:slug",
        name: "blogCategory",
        component: BlogCategoryView,
        beforeEnter: async (to, from, next) => {
          Vue.prototype.$Progress.finish();
          next();
        },
      },
      {
        path: "blogs-hashtag/:hashtag",
        name: "blogHashTag",
        component: BlogHashTagView,
        beforeEnter: async (to, from, next) => {
          Vue.prototype.$Progress.finish();
          next();
        },
      },
    ],
  },
  {
    path: "/",
    component: BlankLayout,
    children: [
      {
        path: "error",
        component: Error,
        name: "error",
        beforeEnter: async (to, from, next) => {
          Vue.prototype.$Progress.finish();
          next();
        },
      },
      // {
      //     path: "register",
      //     component: Register,
      //     name: "register",
      // },
      // {
      //     path: "login",
      //     name: "login",
      //     component: Login,
      //
      // },
    ],
  },
  {
    path: "/:pathMatch(.*)*",
    name: "shop",
    component: AppLayout,
    children: [
      {
        path: "",
        component: DynamicShop,
        name: "dynamic_shop",
        beforeEnter: async (to, from, next) => {
          // Skip if the path starts with /team/
          if (to.path.startsWith("/team/")) {
            return next(false);
          }
          // Vue.prototype.$Progress.finish()
          next();
        },
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

export default router;
