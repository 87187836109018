<template>
  <div>
    <HeroHeader :titles="titles" />
    <div ref="targetDiv" style="position: absolute; top: 7%"></div>

    <v-container fluid id="scrollContainer">
      <v-row justify="center">
        <v-col class="col-md-12 col-12">
          <v-row style="max-width: 1280px; margin: 0 auto">
            <v-col class="col-12">
              <v-card class="mb-6 pa-4" v-if="teamMember">
                <v-row>
                  <v-col cols="12" md="3" class="text-center">
                    <div
                      class="team-image-container mx-auto"
                      :style="$inlineBgImage(teamMember.image)"
                    ></div>
                  </v-col>
                  <v-col cols="12" md="9">
                    <h2 class="text-h4 font-weight-bold primary--text">
                      {{ teamMember.name }}
                    </h2>
                    <p
                      class="text-subtitle-1 text-uppercase"
                      style="margin-bottom: 1px"
                    >
                      {{ teamMember.position }}
                    </p>
                    <p class="" style="margin-bottom: 1px">
                      Call: {{ teamMember.phone }}
                    </p>
                    <p class="">{{ teamMember.mail }}</p>
                    <p class="mt-4">
                      {{
                        teamMember.description ||
                        "Real estate professional with expertise in helping clients find their perfect property."
                      }}
                    </p>
                    <div class="mt-4">
                      <a
                        :href="teamMember.fb"
                        v-if="teamMember.fb"
                        target="_blank"
                        class="mr-3"
                      >
                        <v-icon class="team-fb-icon">mdi-facebook</v-icon>
                      </a>
                      <a
                        :href="teamMember.linkedin"
                        v-if="teamMember.linkedin"
                        target="_blank"
                        class="mr-3"
                      >
                        <v-icon class="team-li-icon">mdi-linkedin</v-icon>
                      </a>
                      <a
                        :href="teamMember.insta"
                        v-if="teamMember.insta"
                        target="_blank"
                        class="mr-3"
                      >
                        <v-icon class="team-ig-icon">mdi-instagram</v-icon>
                      </a>
                      <a
                        :href="teamMember.youtube"
                        v-if="teamMember.youtube"
                        target="_blank"
                        class="mr-3"
                      >
                        <v-icon class="team-youtube-icon">mdi-youtube</v-icon>
                      </a>
                      <v-btn class="icon-btn"
                        ><a :href="teamMember.tiktok" v-if="teamMember.tiktok">
                          <v-img
                            class="team-tiktok-icon"
                            :src="require('@/assets/img/tiktok.png')"
                            max-width="18px"
                          /> </a
                      ></v-btn>
                    </div>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>

          <v-row style="max-width: 1280px; margin: 0 auto">
            <v-col class="col-12 pa-3">
              <h3 class="text-h5 font-weight-bold primary--text mb-4">
                Properties Listed by
                {{ teamMember ? teamMember.name : "This Agent" }} (
                {{ totalProperties }} )
              </h3>

              <v-row
                v-if="teamProperties.length > 0"
                class="equal-height-cards"
              >
                <v-col
                  class="col-md-3 col-sm-6 col-12 filter-card-container"
                  v-for="(item, n) in teamProperties"
                  :key="n"
                >
                  <div class="property-card-wrapper">
                    <PropertyCard :item="item" />
                  </div>
                </v-col>
              </v-row>

              <v-row v-else>
                <template v-if="isLoading">
                  <div style="text-align: center; width: 100%">
                    <v-progress-circular
                      indeterminate
                      :size="70"
                      :width="7"
                      color="#00bbe4"
                    ></v-progress-circular>
                  </div>
                </template>

                <template v-else>
                  <div class="col-12 empty-data-container">
                    <div class="d-flex justify-center">
                      <div class="no-image-container">
                        <v-img src="@/assets/img/not-found.jpg" cover />
                      </div>
                    </div>
                    <p class="text-h5 text-center no-properties-text">
                      No Properties Found for this Team Member
                    </p>
                  </div>
                </template>
              </v-row>
            </v-col>
          </v-row>

          <v-row>
            <v-col class="col-12" v-if="pagination.total > 1">
              <v-pagination
                v-model="pagination.current"
                :length="pagination.total"
                :total-visible="pagination.totalVisible"
                @input="paginateNext"
              ></v-pagination>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import HeroHeader from "@/components/HeroHeader";
import PropertyCard from "@/components/PropertyCard";
import { mapState } from "vuex";

export default {
  name: "TeamMemberPropertiesView",
  components: {
    HeroHeader,
    PropertyCard,
  },
  data() {
    return {
      teamId: null,
      teamMember: null,
      teamProperties: [],
      totalProperties: 0,
      pagination: {
        current: 1,
        total: 0,
        totalVisible: 7,
      },
      titles: {
        mainTitle: "Team Member Properties",
        subTitle: "Properties listed by our team member",
      },
    };
  },
  computed: {
    ...mapState(["teams", "isLoading"]),
  },
  methods: {
    gotoViewer() {
      this.$nextTick(() => {
        const targetDiv = this.$refs.targetDiv;
        targetDiv.scrollIntoView({ behavior: "smooth", block: "start" });
      });
    },
    navigateToProperty(property) {
      if (property && property.slug) {
        this.$router.push({
          name: "singleProperty",
          params: { slug: property.slug },
        });
      }
    },
    paginateNext(value) {
      this.pagination.current = value;
      this.fetchTeamMemberProperties();
    },
    async fetchTeamMemberProperties() {
      if (!this.teamId) {
        console.error("No team ID provided");
        return;
      }

      this.$store.commit("setLoading", true);
      try {
        console.log("Fetching properties for team ID:", this.teamId);

        // Use the axios instance from Vue prototype with full URL path
        const apiUrl = `team/${this.teamId}/properties?page=${this.pagination.current}`;
        console.log("API URL:", apiUrl);

        const response = await this.$axios.get(apiUrl);
        console.log("Team properties API response:", response.data);

        if (response.data && response.data.success) {
          // Ensure each property has the required fields
          const properties = response.data.data.data || [];
          this.totalProperties = response.data.data.total;

          // Process properties to ensure they have the required structure
          this.teamProperties = properties.map((property) => {
            const images = property.media.map((media) => media.original_url);
            return {
              ...property,
              // Ensure images array exists
              images: images || [],
              // Ensure features array exists
              features: property.features || [],
              // Ensure other required fields have default values if missing
              name: property.name || "Unnamed Property",
              slug: property.slug || "",
              for: property.for || "Sale",
              price: property.price || "Price on Request",
              code: property.code || "",
              location: property.location || "Location not specified",
            };
          });

          // If pagination data is available in the response
          if (response.data.data) {
            const total = response.data.data.total;
            const perPage = response.data.data.per_page;
            const currentPage = response.data.data.current_page;
            const lastPage = Math.ceil(total / perPage);

            this.pagination.current = currentPage;
            this.pagination.total = lastPage;
          }
        } else {
          console.warn("No properties found or API returned error");
          this.teamProperties = [];
        }
      } catch (error) {
        console.error("Error fetching team member properties:", error);
        this.teamProperties = [];
      } finally {
        this.$store.commit("setLoading", false);
      }
    },
    findTeamMember() {
      if (this.teams.length > 0) {
        this.teamMember = this.teams.find((team) => team.id == this.teamId);
        if (this.teamMember) {
          this.titles.mainTitle = `${this.teamMember.name}'s Properties`;
          this.titles.subTitle = `Properties listed by ${this.teamMember.name}`;
        }
      }
    },
  },
  watch: {
    teams() {
      this.findTeamMember();
    },
    "$route.params.teamId"() {
      this.teamId = this.$route.params.teamId;
      this.findTeamMember();
      this.fetchTeamMemberProperties();
    },
  },
  mounted() {
    // Get the teamId from the route params
    this.teamId = this.$route.params.teamId;
    console.log("TeamMemberPropertiesView mounted with teamId:", this.teamId);
    console.log("Route params:", this.$route.params);

    if (!this.teamId) {
      console.error("No team ID found in route params");
      return;
    }

    // Fetch team data if not already loaded
    if (this.teams.length === 0) {
      this.$store.dispatch("fetchTeam").then(() => {
        this.findTeamMember();
      });
    } else {
      this.findTeamMember();
    }

    // Make sure to call this after setting teamId
    this.fetchTeamMemberProperties();
    this.gotoViewer();
  },
  metaInfo() {
    return {
      title: this.teamMember
        ? `${this.teamMember.name}'s Properties`
        : "Team Member Properties",
      titleTemplate: this.$root.$options.titleTemplate,
    };
  },
};
</script>

<style scoped>
.icon-btn {
  padding: 0px;
  height: 20px !important;
  max-width: 20px !important;
  min-width: 20px !important;
  background-color: transparent !important;
  box-shadow: none !important;
}
.team-image-container {
  height: 15rem !important;
  width: 15rem !important;
  border-radius: 50%;
  background-size: cover;
  background-position: top;
  border: 1px solid rgba(98, 160, 194, 0.56);
}

.team-fb-icon,
.team-li-icon,
.team-ig-icon,
.team-tiktok-icon {
  font-size: 1.8em;
  margin-left: 5px;
}

.equal-height-cards {
  display: flex;
  flex-wrap: wrap;
}

.filter-card-container {
  padding: 15px;
  position: relative;
  height: 100%;
  display: flex;
}

.property-card-wrapper {
  width: 100%;
  height: 100%;
  cursor: pointer;
  transition: transform 0.2s;
}

.property-card-wrapper:hover {
  transform: translateY(-5px);
}

.no-image-container {
  max-height: 350px;
  max-width: 350px;
}

.empty-data-container {
  text-align: center;
}

.no-properties-text {
  font-weight: bold !important;
  color: #436f7b;
}
</style>
