<template>
  <div>
    <!--    MAIN HERO HEADER HOME-->
    <v-container fluid class="p-hero-header home-header pb-0">
      <video autoplay muted loop id="myVideo">
        <source
          :src="
            banners.video.length == 0
              ? require('./../assets/video.mp4')
              : banners.video
          "
          type="video/mp4"
        />
      </video>

      <v-row>
        <v-col class="p-hero-content col-12">
          <v-row v-if="banners">
            <v-col class="col-md-6 col-12 tag-wrapper">
              <h3 class="p-hero-title shadow-text">{{ banners.title }}</h3>
              <p class="p-hero-subtitle shadow-text">{{ banners.sub_title }}</p>
              <v-btn class="blue--btn" :to="{ name: 'top-listing' }">
                Get Started</v-btn
              >
            </v-col>
            <v-col class="col-md-6 col-12" v-show="false">
              <carousel-3d>
                <slide v-for="(slide, i) in banners.images" :index="i" :key="i">
                  <div :style="$inlineBgImage(slide)" class="slide-image"></div>
                </slide>
              </carousel-3d>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>

    <v-col cols="12" class="overlay-search">
      <v-row>
        <v-col class="p-hero-content mb-0 pb-0 px-0">
          <v-row justify="center" class="">
            <v-col class="col-md-11 col-12">
              <v-row
                class="search-container-home"
                :class="$vuetify.breakpoint.smAndDown ? 'pa-4' : ''"
              >
                <v-col class="col-md-6 col-12 pb-0">
                  <v-text-field
                    class="p-search"
                    label="Search through name, address and property codes "
                    solo
                    hide-details="true"
                    @keydown.enter="searchNow"
                    v-model="search.words"
                  ></v-text-field>
                </v-col>

                <v-col class="col-md-2 col-6 pb-0">
                  <v-select
                    class="p-search"
                    solo
                    hide-details="true"
                    :items="priceFilters"
                    label="Price Range"
                    @change="filterPriceFunction"
                    v-model="selectedPriceFilter"
                  />
                </v-col>

                <!--                  <v-col class="col-md-2 col-4">-->
                <!--                    <v-text-field-->
                <!--                        class="p-search"-->
                <!--                        label="Min Price"-->
                <!--                        v-model="search.minPrice"-->
                <!--                        hide-details=true-->
                <!--                        solo-->
                <!--                    ></v-text-field>-->
                <!--                  </v-col>-->

                <!--                  <v-col class="col-md-2 col-4">-->
                <!--                    <v-text-field-->
                <!--                        class="p-search"-->
                <!--                        label="Max Price"-->
                <!--                        hide-details=true-->
                <!--                        v-model="search.maxPrice"-->
                <!--                        solo-->
                <!--                    ></v-text-field>-->
                <!--                  </v-col>-->

                <v-col class="col-md-2 col-6 pb-0">
                  <v-select
                    class="p-search"
                    :items="filter"
                    label="Type"
                    hide-details="true"
                    v-model="search.propertyType"
                    solo
                  ></v-select>
                </v-col>

                <v-col md="2" cols="12">
                  <v-btn class="p-search-btn" x-large block @click="searchNow">
                    Search
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>

    <!--    //featured-->

    <!-- <v-container fluid class="p-content p-featured fix-width pt-16">
      <v-row>
        <v-col class="text-center">
          <v-btn class="darkblue--btn"> Featured Properties</v-btn>

        </v-col>
      </v-row>
      <FeaturedComponent />
    </v-container> -->

    <!--premium properties-->

    <PremiumComponent />

    <!--premium properties-->

    <NewListingComponent />

    <!--under construction properties-->
    <UnderConstructionProperties />

    <!--    ABOUT US HOME-->
    <v-container
      fluid
      class="p-about fix-width"
      v-if="Object.keys(home_about).length !== 0"
    >
      <v-row justify="center">
        <v-col class="col-md-11 col-12">
          <v-row>
            <v-col class="col-md-6 col-12">
              <v-row>
                <div
                  class="col-md-9 offset-md-3 image-border"
                  :style="$inlineBgImage('hero-header.jpg')"
                >
                  <div class="about-info-clients">
                    {{ contact_details.total_clients }}+ clients
                  </div>
                </div>
              </v-row>
              <svg width="500" height="500">
                <circle
                  cx="250"
                  cy="300"
                  r="150"
                  stroke="black"
                  stroke-width="2"
                  fill="red"
                />
              </svg>
            </v-col>
            <v-col class="col-md-6 col-12">
              <v-row justify="center">
                <v-col class="col-11">
                  <v-row>
                    <v-col class="col-12">
                      <v-btn class="darkblue--btn"> About us</v-btn>

                      <h3 class="p-about-title">
                        {{ home_about.title }}
                      </h3>

                      <div v-html="home_about.description"></div>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="col-12">
                      <!--                      <v-row class="about-checks">-->
                      <!--                        <v-col class="col-6 py-1">-->
                      <!--                          <p class="mb-0">-->
                      <!--                            <v-icon>mdi-check</v-icon> One Stop Solution-->
                      <!--                          </p>-->
                      <!--                        </v-col>-->
                      <!--                        <v-col class="col-6 py-1">-->
                      <!--                          <p class="mb-0">-->
                      <!--                            <v-icon>mdi-check</v-icon> Free Consulation-->
                      <!--                          </p>-->
                      <!--                        </v-col>-->
                      <!--                        <v-col class="col-6 py-1">-->
                      <!--                          <p class="mb-0">-->
                      <!--                            <v-icon>mdi-check</v-icon> Low Commission-->
                      <!--                          </p>-->
                      <!--                        </v-col>-->
                      <!--                        <v-col class="col-6 py-1">-->
                      <!--                          <p class="mb-0">-->
                      <!--                            <v-icon>mdi-check</v-icon> Property Insurance-->
                      <!--                          </p>-->
                      <!--                        </v-col>-->
                      <!--                      </v-row>-->

                      <v-row>
                        <v-col class="col-12">
                          <v-btn
                            class="blue--btn"
                            large
                            :to="{ name: 'about-us' }"
                          >
                            Learn More
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>

    <!--achievements-->
    <!-- <v-container class="p-content p-achievements">
      <v-row justify="center">
        <v-col class="col-12 text-center">
          <v-btn class="lightblue-large-btn" height="45px ">
            Achievements
          </v-btn>
        </v-col>
        <v-col class="col-md-9 text-center">
          <v-row>
            <v-col
              class="col-md-2 col-4 single-achievement"
              v-for="(a, i) in achievements"
              :key="i"
            >
              <span class="circle-bg" v-html="a.icon" />
              <p>
                {{ a.title }}
                <span
                  ><br />
                  {{ a.count }}</span
                >
              </p>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container> -->
    <HomeBlogSection />

    <!--testimonial-->
    <TestimonialComponent :testimonials="testimonials" />
    <FAQ />
  </div>
</template>

<script>
// import FeaturedComponent from "@/components/FeaturedComponent";
import PremiumComponent from "@/components/PremiumComponent";
import NewListingComponent from "@/components/NewListingComponent";
import UnderConstructionProperties from "@/components/UnderConstructionProperties";
import TestimonialComponent from "@/components/TestimonialComponent.vue";
import HomeBlogSection from "@/components/HomeBlogSection";
import { Carousel3d, Slide } from "vue-carousel-3d";
import { mapState } from "vuex";
import FAQ from "@/components/FAQ";

export default {
  name: "Home",
  metaInfo() {
    return {
      title: "Home",
      titleTemplate: this.$root.$options.titleTemplate,
      meta: [
        {
          name: "title",
          content: "Property In Nepal",
        },
        {
          name: "description",
          content: "Welcome Property In Nepal",
        },
      ],
    };
  },
  components: {
    PremiumComponent,
    // FeaturedComponent,
    NewListingComponent,
    UnderConstructionProperties,
    Carousel3d,
    Slide,
    HomeBlogSection,
    TestimonialComponent,
    FAQ,
  },
  data() {
    return {
      slides: ["hero-header.jpg", "hero-header.jpg", "hero-header.jpg"],
      filter: ["Land", "House", "Apartment"],
      priceFilters: [
        "All Price Range",
        "Less than 1 cr",
        "1 crore  - 2 crore",
        "2 crore  - 3 crore",
        "3 crore  - 4 crore",
        "4 crore  - 6 crore",
        "6 crore  - 10 crore",
        "Above than 10 crore",
      ],
      priceRanges: [
        ["", ""],
        ["", 1],
        [1, 2],
        [2, 3],
        [3, 4],
        [4, 6],
        [6, 10],
        [10, ""],
      ],
      selectedFilter: "Land",
      selectedPriceFilter: "All Price Range",
      search: {
        minPrice: "",
        maxPrice: "",
        words: "",
        propertyType: "",
      },
    };
  },
  computed: {
    ...mapState([
      "banners",
      "testimonials",
      "contact_details",
      "achievements",
      "home_about",
    ]),
  },
  beforeMount() {},
  methods: {
    filterPriceFunction() {
      let selectedPrice = this.selectedPriceFilter;
      let index = this.priceFilters.indexOf(selectedPrice);
      let ranges = this.priceRanges[index];
      if (ranges != " ") {
        this.search.minPrice = ranges[0] !== "" ? ranges[0] * 10000000 : "";
        this.search.maxPrice = ranges[1] !== "" ? ranges[1] * 10000000 : "";
      } else {
        this.search.minPrice = "";
        this.search.maxPrice = "";
      }

      console.log(this.search.minPrice, this.search.maxPrice);
    },
    searchNow() {
      let filteredSearch = {};
      Object.keys(this.search).forEach((key) => {
        if (this.search[key] !== "") {
          filteredSearch[key] = this.search[key];
        }
      });

      this.$router.push({ path: "search", query: { ...filteredSearch } });

      this.$store.dispatch("searchProperty", filteredSearch);
    },
  },
};
</script>

<style>
.home-header {
  position: relative;
  overflow: hidden;
  height: 600px;
}
#myVideo {
  position: absolute;
  left: 0;
  top: -15%;
  width: 100%;
}
.shadow-text {
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.51);
}

.overlay-search {
  margin-top: -120px;
  z-index: 4 !important;
  position: relative;
  padding-bottom: 0;
}

.p-search-btn {
  min-height: 45px;
  height: 95% !important;
  border-radius: 10px;
  font-weight: bold;
}
</style>
