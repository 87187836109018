<template>
  <div>
    <HeroHeader :titles="titles" />
    <v-container class="my-10">
      <v-row>
        <v-col cols="12" class="text-center mb-8">
          <h2 class="text-h4 font-weight-bold primary--text">Our Videos</h2>
          <p class="text-subtitle-1 mt-4">
            Watch our latest videos showcasing properties and real estate
            insights.
          </p>
        </v-col>
      </v-row>

      <v-row v-if="isLoading">
        <v-col cols="12" class="text-center">
          <v-progress-circular
            indeterminate
            color="primary"
            size="64"
          ></v-progress-circular>
        </v-col>
      </v-row>

      <v-row v-else>
        <v-col
          v-for="(video, index) in videos"
          :key="index"
          cols="12"
          md="6"
          lg="4"
          class="mb-8"
        >
          <v-card class="video-card h-100" elevation="3">
            <div class="video-container">
              <iframe
                :src="`https://www.youtube.com/embed/${video.videoId}`"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
            <v-card-title>{{ video.title }}</v-card-title>
            <v-card-text>
              <p>{{ video.description }}</p>
              <v-divider class="my-3"></v-divider>
              <div class="d-flex align-center">
                <v-icon small class="mr-1">mdi-calendar</v-icon>
                <span class="caption">{{ formatDate(video.publishedAt) }}</span>
                <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  text
                  small
                  :href="`https://www.youtube.com/watch?v=${video.videoId}`"
                  target="_blank"
                >
                  <v-icon small left>mdi-youtube</v-icon>
                  Watch on YouTube
                </v-btn>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row v-if="videos.length === 0 && !isLoading">
        <v-col cols="12" class="text-center">
          <v-alert type="info">
            No videos found. Please check back later.
          </v-alert>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" class="text-center mt-4">
          <v-btn
            color="primary"
            outlined
            href="https://www.youtube.com/@propertynepal"
            target="_blank"
          >
            <v-icon left>mdi-youtube</v-icon>
            Visit Our YouTube Channel
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import HeroHeader from "@/components/HeroHeader";

export default {
  name: "VideosView",
  components: {
    HeroHeader,
  },
  data() {
    return {
      titles: {
        mainTitle: "Our Videos",
        subTitle: "Property Showcases & Real Estate Insights",
      },
      isLoading: false,
      videos: [],
    };
  },
  methods: {
    fetchVideos() {
      this.isLoading = true;

      this.$store
        .dispatch("fetchYoutubeVideos")
        .then((videos) => {
          this.videos = videos;
          this.isLoading = false;
        })
        .catch((error) => {
          console.error("Error fetching videos:", error);
          this.isLoading = false;

          // Fallback to sample data if API fails
          this.videos = [
            {
              videoId: "UHhUtkMhO_U",
              title: "3.5 Aana |20 Feet Road |4 BHK | Furnished",
              description: "Property showcase in Lalitpur",
              publishedAt: "2023-03-03T15:24:38+00:00",
            },
            // ... other fallback videos
          ];
        });
    },
    formatDate(dateString) {
      const options = { year: "numeric", month: "long", day: "numeric" };
      return new Date(dateString).toLocaleDateString(undefined, options);
    },
  },
  mounted() {
    this.fetchVideos();
  },
  metaInfo() {
    return {
      title: "Our Videos",
      titleTemplate: this.$root.$options.titleTemplate,
    };
  },
};
</script>

<style scoped>
.h-100 {
  height: 100%;
}
.video-card {
  transition: transform 0.3s;
}
.video-card:hover {
  transform: translateY(-5px);
}
.video-container {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 aspect ratio */
  height: 0;
  overflow: hidden;
}
.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>
