<template>
  <div class="property-card-container" @click="navigateToProperty">
    <div class="property-card">
      <div class="property-image">
        <img :src="item.images[0]" :alt="item.name" />
        <div class="property-tag">{{ item.for }}</div>
      </div>

      <div class="property-price" v-if="item.on_calling == '1'">On Call</div>
      <div class="property-price" v-else>
        {{ item.price }}
      </div>

      <div class="property-details">
        <h3 class="property-title text-truncate">{{ item.name }}</h3>
        <div class="property-id">
          <v-icon small style="color: darkblue">mdi-tag</v-icon> Property ID:
          {{ item.code }}
        </div>
        <div class="property-location">
          <v-icon small style="color: darkblue">mdi-map-marker</v-icon>
          {{ item.location }}, {{ item.city }}
        </div>
        <div class="property-features">
          <div style="display: flex; flex-wrap: wrap; gap: 5px">
            <div
              v-for="(feature, index) in item.features.slice(0, 3)"
              :key="index"
              class="feature"
            >
              <img :src="feature.icon" :alt="feature.name" height="16" />
              {{ feature.value }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PropertyCard",
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  methods: {
    navigateToProperty() {
      if (this.item && this.item.slug) {
        this.$router.push({
          name: "singleProperty",
          params: { slug: this.item.slug },
        });
      }
    },
  },
};
</script>

<style scoped>
.property-card-container {
  width: 100%;
  height: 100%;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.property-card-container:hover {
  transform: translateY(-5px);
}

.property-card {
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  background: white;
  margin-bottom: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
}

.property-card-container:hover .property-card {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

.property-image {
  position: relative;
  height: 200px;
  width: 100%;
}

.property-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.property-tag {
  position: absolute;
  top: 10px;
  left: 10px;
  background: #ff0000;
  color: white;
  padding: 5px 10px;
  border-radius: 4px;
  text-transform: uppercase;
  font-size: 12px;
}

.property-price {
  margin-top: 10px;
  color: #184a62;
  border-radius: 4px;
  font-size: 18px;
  font-weight: bold;
  padding: 0 10px;
}

.property-details {
  padding: 0 10px 10px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.property-title {
  font-size: 16px;
  font-weight: 600;
  line-height: 1.1;
  color: #333;
  margin-bottom: 5px;
}

.property-id {
  color: #333;
  font-size: 14px;
  margin-bottom: 5px;
}

.property-location {
  color: #666;
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 5px;
}

.property-features {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-top: auto;
}

.feature {
  display: flex;
  align-items: center;
  gap: 5px;
  color: #666;
  font-size: 14px;
}
</style>
