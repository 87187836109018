<template>
  <div class="navbar">
    <v-navigation-drawer
      v-model="drawer"
      app
      temporary
      right
      class="hidden-md-and-up"
    >
      <!-- Navigation links here -->
      <v-list>
        <template v-for="(link, n) in links">
          <v-list-group prefix-icon="mdi-folder" v-if="link.children" :key="n">
            <template v-slot:activator>
              <v-list-item-title>{{ link.name }}</v-list-item-title>
            </template>

            <v-list-item
              v-for="(item, index) in link.children"
              :key="index"
              :to="'/' + item.url"
              class="nav-links"
            >
              <v-list-item-title>{{ item.name }}</v-list-item-title>
            </v-list-item>
          </v-list-group>

          <template v-else>
            <v-list-item
              v-if="link.url.includes('https')"
              target="_blank"
              :href="link.url"
              :key="n"
              exact
            >
              <v-list-item-icon v-if="link.icon">
                <v-icon>{{ link.icon }}</v-icon>
              </v-list-item-icon>

              <v-list-item-title>{{ link.name }}</v-list-item-title>
            </v-list-item>
            <v-list-item v-else :to="{ name: link.url }" :key="n" exact>
              <v-list-item-icon v-if="link.icon">
                <v-icon>{{ link.icon }}</v-icon>
              </v-list-item-icon>

              <v-list-item-title>{{ link.name }}</v-list-item-title>
            </v-list-item>
          </template>
        </template>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app dark height="90">
      <div class="logo-main-container">
        <router-link :to="{ name: 'home' }">
          <v-img
            :src="
              contact_details.logo
                ? contact_details.logo
                : '@/assets/img/logo.png'
            "
            class="logo-main"
            rel="preload"
            contain
          ></v-img>
          <!--        <v-img src="@/assets/img/logo.png"  class="logo-main" contain rel="preload"></v-img>-->
        </router-link>
      </div>
      <!-- <v-btn
        text
        icon
        @click.stop="drawer = true"
        class="hidden-md-and-up class-logo"
      >
        <v-img src="@/assets/img/logo.png" class="logo-small" contain></v-img>
      </v-btn> -->
      <v-spacer></v-spacer>
      <v-toolbar-items class="hidden-sm-and-down">
        <template v-for="(link, n) in links">
          <v-menu offset-y v-if="link.children" :key="n">
            <template v-slot:activator="{ on }">
              <v-btn text v-on="on">
                <span class="navbar-links">
                  {{ link.name }}
                </span>
                <v-icon>mdi-chevron-down</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item
                v-for="(item, index) in link.children"
                :key="index"
                class="nav-links"
                :to="'/' + item.url"
              >
                <v-list-item-title>{{ item.name }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>

          <template v-else>
            <v-btn
              v-if="link.url.includes('https')"
              class="navbar-links"
              text
              target="_blank"
              :href="link.url"
              exact
              :key="n"
              >{{ link.name }}</v-btn
            >

            <v-btn
              v-else
              text
              :to="{ name: link.url }"
              class="navbar-links"
              exact
              :key="n"
              >{{ link.name }}</v-btn
            >
          </template>
        </template>
      </v-toolbar-items>
      <v-btn
        text
        icon
        @click.stop="drawer = true"
        class="hidden-md-and-up burger-menu"
      >
        <v-icon>mdi-menu</v-icon>
      </v-btn>
      <!--<v-spacer></v-spacer>
      <v-img src="@/assets/img/logo.png" class="logo-small" contain></v-img>
      <v-toolbar-items class="hidden-sm-and-down">
        <v-btn text :to="{ name: 'login' }">Login</v-btn>
        <v-btn text :to="{ name: 'register' }">Register</v-btn>
      </v-toolbar-items> -->
    </v-app-bar>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      drawer: false,
      links: [
        {
          name: "Home",
          url: "home",
        },
        {
          name: "Buy",
          children: [
            {
              name: "House Sale",
              url: "house-sale",
            },
            {
              name: "Colony House Sale",
              url: "colonyhouse-sale",
            },
            {
              name: "Land Sale",
              url: "land-sale",
            },
            {
              name: "Apartment Sale",
              url: "apartment-sale",
            },
            {
              name: "Commercial Property Sale",
              url: "commercialproperty-sale",
            },
          ],
        },
        {
          name: "Rent",
          children: [
            {
              name: "House Rent",
              url: "house-rent",
            },
            {
              name: "Colony House Rent",
              url: "colonyhouse-rent",
            },
            {
              name: "Land Lease",
              url: "land-lease",
            },
            {
              name: "Apartment Rent",
              url: "apartment-rent",
            },
            {
              name: "Commercial Property Rent",
              url: "commercialproperty-rent",
            },
          ],
        },
        {
          name: "Videos",
          url: "videos",
        },
        {
          name: "Listings",
          children: [
            {
              name: "Premium Listings",
              url: "listings?premium=true",
            },
            {
              name: "New Listings",
              url: "listings?new_listing=true",
            },
            {
              name: "Under Construction",
              url: "listings?propertyFor=under-construction",
            },
            {
              name: "Sold Properties",
              url: "listings?propertyFor=sold",
            },
          ],
        },

        {
          name: "Tools",
          children: [
            {
              name: "EMI Calculator",
              url: "emi",
            },
            {
              name: "Unit Converter",
              url: "unit-converter",
            },
          ],
        },
        {
          name: "Company Info",
          children: [
            {
              name: "About Us",
              url: "about-us",
            },
            {
              name: "Our Branches",
              url: "our-branches",
            },
            {
              name: "Our Team",
              url: "our-team",
            },
            {
              name: "Our Services",
              url: "our-services",
            },
            {
              name: "Sold Property",
              url: "sold-property",
            },
            {
              name: "Blogs",
              url: "blogs",
            },
            {
              name: "Contact Us",
              url: "contact-us",
            },
          ],
        },
        {
          name: "Login",
          url: "https://api.propertyinnepal.com.np",
        },
      ],
    };
  },
  computed: {
    ...mapState(["contact_details"]),
  },
};
</script>

<style>
.v-app-bar,
.v-app-bar--fixed,
.v-app-bar--is-scrolled,
.v-toolbar__content {
  position: relative;
  z-index: 9 !important;
  background: white;
  transition: background-color 1s ease !important;
}

.v-toolbar__items.hidden-sm-and-down * {
  color: black;
}

.nav-links * {
  text-decoration: none;
}

.navbar .v-btn--active {
  background: linear-gradient(90deg, #1e4d62 0.1%, #00b5e7 100%);

  /*background-color: rgb(17, 190, 232) !important;*/
  color: white !important;
}

.v-btn.v-btn--text.theme--dark.v-size--default:focus {
  background-color: rgba(16, 57, 74, 0.58) !important;
  color: white !important;
}
.navbar .v-list-item--active {
  background-color: #253940;
  color: white;
}

a.v-btn.v-btn--router.v-btn--text.theme--dark.v-size--default:hover,
button.v-btn.v-btn--text.theme--dark.v-size--default:hover,
.navbar-links:hover {
  background: linear-gradient(
    90deg,
    rgba(221, 237, 246, 0.82) 10%,
    #61d1ee 100%
  );
}

.nav-links:hover {
  /*background-color: rgba(30, 77, 98, 0.68);*/
  background: linear-gradient(
    90deg,
    rgba(221, 237, 246, 0.82) 10%,
    #61d1ee 100%
  );

  text-decoration: none;
}
.nav-links.v-list-item--active {
  background-color: rgb(17, 190, 232) !important;
  color: white;
}
.nav-links .v-list-item__title:active {
  color: white !important;
}

.v-btn--active * {
  color: white !important;
}
.v-navigation-drawer {
  z-index: 999 !important;
}

.logo-small {
  margin-top: 30px;
  width: 120px !important;
}

.v-navigation-drawer {
  z-index: 999 !important;
}
.class-logo.hidden-md-and-up {
  margin-left: 30px !important;
  color: black !important;
}

.burger-menu.hidden-md-and-up {
  color: black !important;
  padding: 80px !important;
  padding: 32px !important;
}

.nav-links * {
  text-decoration: none;
}

.logo-main-container {
  max-width: 145px;
  height: 100%;
  margin-left: 2%;
}
.logo-main {
  height: 100% !important;
}

.navbar-links {
  font-size: 13px !important;
}
</style>
